import {
	getLocaleByReference,
	getParamsFromPath,
	getDefaultSite,
	getSiteByReference
} from './site-utils'
import {HOME_HREF, urlPartPositions} from '../constants'

export const getStaticResourcePath = () => {
	if (typeof window === 'undefined') {
		return
	}

	var path = window.location.origin

	if (window.ReactStaticResources && window.ReactStaticResources.base) {
		path = window.ReactStaticResources.base + 'react'
	}

	return path
}
export const getAppOrigin = () => {
	if (typeof window !== 'undefined') {
		return window.location.origin
	}

	const {APP_ORIGIN} = process.env

	if (!APP_ORIGIN) {
		throw new Error(
			"Application is not initialized. Please ensure '_createApp' has been invoked before using this method."
		)
	}

	return process.env.APP_ORIGIN
}

export const updateSearchParams = (searchParams, newParams) => {
	Object.entries(newParams).forEach(([key, value]) => {
		// 0 is a valid value as for a param
		if (!value && value !== 0) {
			searchParams.delete(key)
		} else {
			searchParams.set(key, value)
		}
	})
}

/**
 * Modifies a given url by adding/updating query parameters.
 *
 * @param {string} url - The base url of the output url set.
 * @param {object} extraParams - A key values pairing used to add static search param values.
 * @returns {string} A URL with additional params
 * @example
 * import {rebuildPathWithParams} from '/path/to/utils/url'
 *
 * rebuildPathWithParams(
 *     '/en-GB/product/25501032M',
 *     {color: 'JJ2SKXX', size: 'MD'}
 * )
 *
 * // Returns
 * // '/en-GB/product/25501032M?color=JJ2SKXX&size=9MD'
 */
export const rebuildPathWithParams = (url, extraParams) => {
	const [pathname, search] = url.split('?')
	const params = new URLSearchParams(search)

	updateSearchParams(params, extraParams)

	// Clean up any trailing `=` for params without values.
	const paramStr = params.toString().replace(/=&/g, '&').replace(/=$/, '')

	// Generate the newly updated url.
	return `${pathname}${Array.from(paramStr).length > 0 ? `?${paramStr}` : ''}`
}

export const buildUrlSet = (url = '', key = '', values = [], extraParams = {}) =>
	values.map((value) => rebuildPathWithParams(url, {[key]: value, ...extraParams}))

/*
 * Remove query params from a give url path based on a given list of keys
 *
 * @param {string} path - The part of url to have params removed from.
 * @param {array} keys - list of params to be removed
 * @returns {string} - the url after param has been removed
 * @example
 * import {removeQueryParamsFromPath} from /path/to/util/url
 *
 * removeQueryParamsFromPath(
 *   /en-GB/cart?pid=1234&color=black&size=s&abc=12,
 *   ['pid', 'color', 'size']
 * )
 * // returns
 * // '/en-GB/cart?abc=12'
 */
export const removeQueryParamsFromPath = (path, keys) => {
	const [pathname, search] = path.split('?')
	const params = new URLSearchParams(search)
	keys.forEach((key) => {
		if (params.has(key)) {
			params.delete(key)
		}
	})

	// Clean up any trailing `=` for params without values.
	const paramStr = params.toString().replace(/=&/g, '&').replace(/=$/, '')

	return `${pathname}${paramStr && '?'}${paramStr}`
}

/**
 * A function that takes a path and qualifies it with the current host and protocol.
 * This function works on the client and on the server.
 *
 * @example
 * absoluteUrl(/women/dresses?color=black)
 *
 * // => http(s)://www.site.com/women/dresses?color=black
 * @param path
 * @returns {string|*}
 */
export const absoluteUrl = (path) => {
	return new URL(path, window.location.origin).toString()
}

/**
 * A function that takes search params from URL and returns search query as string
 * This function works on the client and on the server.
 *
 *
 * // => http(s)://www.site.com/women/dresses?color=black
 * @returns {string|*}
 */
export const getSearchQueryFromURL = () => {
	const urlParams = new URLSearchParams(location.search)
	const queryParm = urlParams.get('q')

	return queryParm || ''
}

/**
 * Generates the URL Template literal (Template string) used to build URLs in the App according
 * the current selected site/locale and the default App URL configuration.
 *
 * @param appConfig Application default configuration.
 * @param siteRef Current selected Site reference. The value can be the Site id or alias.
 * @param localeRef Current selected Locale reference. The value can be the Locale id or alias.
 * @returns {function(*, *, *): string} function providing: path, site and locale generates a URL.
 */
export const createUrlTemplate = (appConfig, siteRef, localeRef) => {
	const {
		site: siteConfig,
		locale: localeConfig,
		showDefaults: showDefaultsConfig,
		appendSitePrefix: appendSitePrefix
	} = appConfig.url
	const defaultSite = getDefaultSite()
	const site = getSiteByReference(siteRef)
	const defaultLocale = getLocaleByReference(site, site.l10n.defaultLocale)

	const isDefaultSite =
		defaultSite.id === siteRef || (defaultSite.alias && defaultSite.alias === siteRef)
	const isDefaultLocale =
		defaultLocale.id === localeRef || (defaultLocale.alias && defaultLocale.alias === localeRef)

	const querySite =
		(siteConfig === urlPartPositions.QUERY_PARAM && showDefaultsConfig) ||
		(siteConfig === urlPartPositions.QUERY_PARAM && !showDefaultsConfig && !isDefaultSite)
	const queryLocale =
		(localeConfig === urlPartPositions.QUERY_PARAM && showDefaultsConfig) ||
		(localeConfig === urlPartPositions.QUERY_PARAM && !showDefaultsConfig && !isDefaultLocale)

	const isQuery = querySite || queryLocale

	const pathSite =
		(siteConfig === urlPartPositions.PATH && showDefaultsConfig) ||
		(siteConfig === urlPartPositions.PATH && !showDefaultsConfig && !isDefaultSite)
	const pathLocale =
		(localeConfig === urlPartPositions.PATH && showDefaultsConfig) ||
		(localeConfig === urlPartPositions.PATH && !showDefaultsConfig && !isDefaultLocale)

	return (path, site, locale) => {
		const isHomeWithDefaultSiteAndLocale =
			path === HOME_HREF &&
			(defaultSite.id === site || (defaultSite.alias && defaultSite.alias === site)) &&
			(defaultLocale.id === locale || (defaultLocale.alias && defaultLocale.alias === locale))

		const sitePath = pathSite && site && !isHomeWithDefaultSiteAndLocale ? `/${site}` : ''
		let localePath = pathLocale && locale && !isHomeWithDefaultSiteAndLocale ? `/${locale}` : ''

		const hasQuery = isQuery && (site || locale) && !isHomeWithDefaultSiteAndLocale
		const sitePrefix = appendSitePrefix ? '/s' : ''
		let queryString = ''

		if (hasQuery) {
			const searchParams = new URLSearchParams()
			querySite && site && searchParams.append('site', site)
			queryLocale && locale && searchParams.append('locale', locale)
			queryString = `?${searchParams.toString()}`
		}

		const isSearch = path.indexOf('search') > -1
		if (locale && isSearch) {
			const paramLocale = defaultLocale?.id.replace('-', '_')
			const langParam = `lang=${paramLocale}`
			path = path.indexOf(langParam) >= 0 ? path : path + `&${langParam}`
		}

		return `${sitePrefix}${sitePath}${localePath}${path}${queryString}`
	}
}

/*
 * Remove site alias and locale from a given url, to be used for "navigate" urls
 *
 * @param {string} pathName - The part of url to have site alias and locale removed from
 * @returns {string} - the path after site alias and locale have been removed
 * @example
 * import {removeSiteLocaleFromPath} from /path/to/util/url
 *
 * removeSiteLocaleFromPath(/RefArch/en-US/account/wishlist)
 * // returns '/account/wishlist'
 */
export const removeSiteLocaleFromPath = (pathName = '') => {
	let {siteRef, localeRef} = getParamsFromPath(pathName)

	// remove the site alias from the current pathName
	if (siteRef) {
		pathName = pathName.replace(new RegExp(`/${siteRef}`, 'g'), '')
	}
	// remove the locale from the current pathName
	if (localeRef) {
		pathName = pathName.replace(new RegExp(`/${localeRef}`, 'g'), '')
	}

	return pathName
}

/**
 * Given a category and the current locale returns an href to the product list page.
 *
 * @param {Object} category
 * @returns {string}
 */
export const categoryUrlBuilder = (category) => encodeURI(`/category/${category.id}`)

/**
 * Given a product and the current locale returns an href to the product detail page.
 *
 * @param {Object} product
 * @returns {string}
 */
export const productUrlBuilder = (product) => encodeURI(`/product/${product.id}`)

/**
 * Given a search term, constructs a search url.
 *
 * @param {string} searchTerm
 * @returns {string}
 */
export const searchUrlBuilder = (searchTerm) => '/search?q=' + encodeURIComponent(searchTerm)
