import {useRef} from 'react'
import {useQuery} from '@tanstack/react-query'

const useProductsSfccGeneral = (ids) => {
	const url = window.ReactUrls.productsInfo
	const prevIdsRef = useRef([])

	const {data, isRefetching, isLoading} = useQuery({
		queryKey: ['productsAvailability', JSON.stringify(ids)],
		queryFn: async () => {
			const response = await fetch(url + '?products=' + ids.join(','), {
				method: 'POST',
				headers: {'X-Requested-With': 'XMLHttpRequest'}
			})

			if (!response.ok) {
				return null
			}

			return response.json()
		},
		enabled: !(
			!ids ||
			ids.length === 0 ||
			!ids[0] ||
			JSON.stringify(ids) === JSON.stringify(prevIdsRef.current)
		)
	})

	return {isLoading, isRefetching, data}
}

export default useProductsSfccGeneral
