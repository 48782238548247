import React, {useEffect} from 'react'
import {createPortal} from 'react-dom'
import PropTypes from 'prop-types'
import useScreenLocker from '../../hooks/use-screen-locker'

const Overlay = ({className, isOpen}) => {
	const activeClassName = 'overlay--active'
	const screenLocker = useScreenLocker()

	useEffect(() => {
		screenLocker.changeState(isOpen)
	}, [isOpen])

	return (
		<React.Fragment>
			{createPortal(
				<div
					id="overlay"
					className={'overlay ' + className + ' ' + (isOpen && activeClassName)}
				/>,
				document.getElementById('wrapper')
			)}
		</React.Fragment>
	)
}

Overlay.propTypes = {
	className: PropTypes.string,
	isOpen: PropTypes.bool
}

export default Overlay
