module.exports = {
	breakpoints: {
		'bp-min': 0,
		'bp-small': 320,
		'bp-small-medium': 768,
		'bp-medium': 1024,
		'bp-medium-max': 1024,
		'bp-max-width': 1344,
		'bp-container-small': 400,
		'bp-container': 1312
	}
}
