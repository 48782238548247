import React from 'react'
import PropTypes from 'prop-types'
import {sortRatingRefinements, extractRatingNumber} from '../../../utils/refinement-utils'

const RatingRefinements = ({filter, toggleFilter, selectedFilters}) => {
	const ratingValues = sortRatingRefinements(filter?.values)

	return (
		<div>
			<ul>
				{ratingValues.map((value) => {
					const isChecked = selectedFilters.includes(value.value)
					return (
						<li
							className="refinement__list"
							key={value.value}
							data-gtm-value={value.value}
						>
							<div className="refinement__item">
								<input
									className="input-checkbox"
									id={value.value}
									type="checkbox"
									checked={isChecked}
									onChange={() =>
										toggleFilter(value, 'trustmateAverageGrade', isChecked)
									}
								/>

								<label className="checkbox-label" htmlFor={value.value}>
									<div className="reviews-stars reviews-stars--refinement">
										<div className="reviews-stars__count">{value.value}</div>
										<div className="reviews-stars__star">
											<span
												className="reviews-stars__value"
												style={{
													width: `${(extractRatingNumber(value.value) / 5) * 100}%`
												}}
											></span>
										</div>
									</div>
									<span>({value.hits_count})</span>
								</label>
							</div>
						</li>
					)
				})}
			</ul>
		</div>
	)
}

RatingRefinements.propTypes = {
	filter: PropTypes.object,
	toggleFilter: PropTypes.func,
	selectedFilters: PropTypes.array
}

export default RatingRefinements
