module.exports = {
	dis: {
		PDP_mobile: {
			scaleWidth: 380,
			scaleHeight: 380,
			scaleMode: 'fit'
		},
		PDP_gallery_mobile: {
			scaleWidth: 1160,
			scaleHeight: 1160,
			scaleMode: 'fit'
		},
		PDP_gallery_tablet: {
			scaleWidth: 1160,
			scaleHeight: 1160,
			scaleMode: 'fit'
		},
		PDP_gallery_desktop: {
			scaleWidth: 600,
			scaleHeight: 600,
			scaleMode: 'fit'
		},
		PDP_gallery_stub: {
			scaleWidth: 580,
			scaleHeight: 580,
			scaleMode: 'fit'
		},
		PLP_mobile: {
			scaleWidth: 144,
			scaleHeight: 144,
			scaleMode: 'fit'
		},
		PLP_mobile_hero_standard: {
			scaleWidth: 230,
			scaleHeight: 130,
			scaleMode: 'fit'
		},
		PDP_tablet: {
			scaleWidth: 380,
			scaleHeight: 380,
			scaleMode: 'fit'
		},
		PLP_tablet: {
			scaleWidth: 170,
			scaleHeight: 170,
			scaleMode: 'fit'
		},
		PLP_tablet_hero_standard: {
			scaleWidth: 250,
			scaleHeight: 170,
			scaleMode: 'fit'
		},
		PDP_desktop: {
			scaleWidth: 600,
			scaleHeight: 600,
			scaleMode: 'fit'
		},
		PDP_zoom: {
			scaleWidth: 1200,
			scaleHeight: 1200,
			scaleMode: 'fit'
		},
		PDP_floating: {
			scaleWidth: 78,
			scaleHeight: 78,
			scaleMode: 'fit'
		},
		every_day_promo: {
			scaleWidth: 98,
			scaleHeight: 98,
			scaleMode: 'fit'
		},
		PLP_desktop: {
			scaleWidth: 240,
			scaleHeight: 240,
			scaleMode: 'fit'
		},
		PLP_desktop_hero_standard: {
			scaleWidth: 280,
			scaleHeight: 280,
			scaleMode: 'fit'
		},
		PLP_thumbnails: {
			scaleWidth: 80,
			scaleHeight: 80,
			scaleMode: 'crop'
		},
		icon: {
			scaleWidth: 55,
			scaleHeight: 55,
			scaleMode: 'fit'
		},
		product_widget: {
			scaleWidth: 430,
			scaleHeight: 430,
			scaleMode: 'fit'
		},
		QUICKVIEW_desktop: {
			scaleWidth: 280,
			scaleHeight: 280,
			scaleMode: 'fit'
		},
		QUICKVIEW_tablet: {
			scaleWidth: 250,
			scaleHeight: 250,
			scaleMode: 'fit'
		},
		QUICKVIEW_mobile: {
			scaleWidth: 380,
			scaleHeight: 380,
			scaleMode: 'fit'
		},
		cart_desktop: {
			scaleWidth: 110,
			scaleHeight: 110,
			scaleMode: 'fit'
		},
		cart_mobile: {
			scaleWidth: 64,
			scaleHeight: 64,
			scaleMode: 'fit'
		},
		mini_cart: {
			scaleWidth: 40,
			scaleHeight: 40,
			scaleMode: 'fit'
		},
		missingImages: {
			'hi-res': 'images/noimage.jpg'
		},
		viewTypes: [
			{
				viewType: [
					'PDP_gallery_mobile',
					'PDP_mobile',
					'PLP_mobile',
					'PLP_mobile_hero_standard',
					'icon',
					'PLP_thumbnails',
					'cart_mobile',
					'QUICKVIEW_mobile'
				],
				scalableViewType: 'hi-res'
			},
			{
				viewType: [
					'PDP_gallery_tablet',
					'PDP_tablet',
					'PLP_tablet',
					'PLP_tablet_hero_standard',
					'icon',
					'PLP_thumbnails',
					'QUICKVIEW_tablet'
				],
				scalableViewType: 'hi-res'
			},
			{
				viewType: [
					'PDP_gallery_desktop',
					'PDP_gallery_stub',
					'product_widget',
					'every_day_promo',
					'PDP_desktop',
					'PLP_desktop',
					'PLP_desktop_hero_standard',
					'icon',
					'PLP_thumbnails',
					'cart_desktop',
					'icon',
					'PDP_zoom',
					'PDP_floating',
					'mini_cart',
					'QUICKVIEW_desktop'
				],
				scalableViewType: 'hi-res'
			}
		],
		imageMissingText: 'The product image is missing!',
		enforceImageScaling: true,
		testImageLocation: 'Site',
		imageExistenceCheck: true,
		useTestImagesOnly: false,
		imageHttpURLReplacement: null,
		imageHttpsURLReplacement: null
	}
}
