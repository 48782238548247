import localConfig from './local'
import developmentConfig from './development'
import stagingConfig from './staging'
import productionConfig from './production'

export const getConfig = () => {
	const hostname = window.location.hostname
	const instanceType = window.ReactPreferences.INSTANCE_TYPE
	const isLocalhost = hostname == 'localhost'
	const isSandbox = hostname.indexOf('bdds') > -1
	let config = localConfig

	if (isLocalhost || isSandbox) {
		return config
	}

	switch (instanceType) {
		case 0:
			config = developmentConfig
			break
		case 1:
			config = stagingConfig
			break
		case 2:
			config = productionConfig
			break
		default:
			config = productionConfig
			break
	}

	return config
}
