import {useEffect, useState} from 'react'

import {getPopularSearch} from '../services/lbx-service'

const usePopularSearch = () => {
	const [popularSearchResult, setPopularSearchResult] = useState(null)

	useEffect(() => {
		const fetchPopularSearch = async () => {
			const result = await getPopularSearch()

			setPopularSearchResult(result)
		}

		fetchPopularSearch()
	}, [])

	return {popularSearchResult}
}

export default usePopularSearch
