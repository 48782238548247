import React from 'react'
import {Route, Routes} from 'react-router-dom'
import ProductList from '../pages/product-list'
import {getConfig} from '../config/config'
import {configureRoutes} from '../utils/routes-utils'

const AppRoutes = () => {
	const routesDefinitions = [
		{
			path: '/search',
			component: <ProductList />
		}
	]

	var routes = configureRoutes(routesDefinitions, getConfig(), {
		ignoredRoutes: []
	})

	return (
		<>
			<Routes>
				{routes.map((route, index) => (
					<Route key={index} path={route.path} element={route.component} />
				))}
			</Routes>
		</>
	)
}

export default AppRoutes
