import React, {useRef, useEffect, useImperativeHandle, forwardRef} from 'react'
import PropTypes from 'prop-types'

const Expandable = forwardRef(
	(
		{
			isOpen = false,
			parent,
			children,
			inner,
			headerContent,
			headerFunction,
			showMoreTextLess,
			showMoreTextMore,
			headerClass = '',
			containerClass = '',
			bodyClass = '',
			showMoreClass = '',
			innerClass = '',
			headerSelector = Expandable.eventNamespace + '__header',
			bodySelector = Expandable.eventNamespace + '__body',
			showMoreSelector = Expandable.eventNamespace + '__show-more',
			innerSelector = Expandable.eventNamespace + '__inner',
			openClass = 'opened',
			initializedClass = 'initialized',
			duration,
			speed,
			minTime,
			maxTime,
			cancelHeaderToggle = false
		},
		ref
	) => {
		let nTransitions = 0
		let lastEvent = null
		const containerRef = useRef(null)
		const bodyRef = useRef(null)
		const innerRef = useRef(null)
		const showMoreRef = useRef(null)
		const recalculateSize = () => {
			let calculated = null

			if (isOpen) {
				calculated = bodyRef.current.scrollHeight + 'px'
				const transitionId = nTransitions

				const watcher = (event) => {
					const isRelated = event.propertyName === 'max-height'
					const isApplicable = transitionId === nTransitions

					if (isRelated || isApplicable) {
						bodyRef.current.removeEventListener('transitionend', watcher)
					}

					let isOpen = containerRef.current.classList.contains(openClass)
					if (isRelated && isApplicable && isOpen) {
						bodyRef.current.style.maxHeight = 'none'
					}
				}

				bodyRef.current.addEventListener('transitionend', watcher)
			}

			bodyRef.current.style.maxHeight = calculated ? calculated : null
		}

		const setInitiallyOpenState = () => {
			containerRef.current.classList.add(openClass)
			bodyRef.current.style.maxHeight = 'none'
			return
		}

		/**
		 * Toggles expandable element.
		 * @param {Boolean} mode Determines if element should be opened (false), closed (true) or toggled (undefined).
		 */
		const toggle = (mode) => {
			isOpen = containerRef.current.classList.contains(openClass)
			mode = mode === undefined ? isOpen : mode
			isOpen = !mode

			if (mode) {
				bodyRef.current.style.maxHeight = bodyRef.current.scrollHeight + 'px'
				getComputedStyle(bodyRef.current).maxHeight
				containerRef.current.classList.remove(openClass)
			} else {
				if (parent) {
					const elements = Array.from(parent.getElementsByClassName(openClass))

					elements.forEach((element) => {
						Expandable.toggle(element)
					})
				}
				containerRef.current.classList.add(openClass)
			}

			isOpen = !mode
			nTransitions++
			recalculateSize()

			const toggleEvent = new Event(
				Expandable.eventNamespace + ':' + isOpen ? 'open' : 'close'
			)
			containerRef.current.dispatchEvent(toggleEvent)
		}

		useImperativeHandle(ref, () => ({
			setInitiallyOpenState,
			toggle,
			containerRef
		}))

		/**
		 * Calculates and changes transition speed if params were provided.
		 */
		const recalculateSpeed = () => {
			let time = duration

			if (speed) {
				let height = 0
				height += bodyRef.current.scrollHeight
				time = height / speed
				time = time < minTime ? minTime : time
				time = time > maxTime ? maxTime : time
			}

			if (time) {
				bodyRef.current.style.transition = 'max-height ' + time + 's ease-in-out'
			}
		}

		const transitionStartHandler = (e) => {
			if (e.target !== bodyRef.current || isOpen || !lastEvent) {
				return
			}

			if ((bodyRef.current.clientHeight * 100) / window.innerHeight < 70) {
				return
			}

			bodyRef.current.scrollIntoView(true)
			const hideMenuEvent = new Event('menu:hide')
			document.dispatchEvent(hideMenuEvent)
		}

		const transitionEndHandler = () => {
			const eventName = isOpen ? 'opened' : 'closed'

			if (lastEvent !== eventName) {
				lastEvent = eventName
				const toggleEvent = new Event(Expandable.eventNamespace + ':' + eventName)
				const transitionEndEvent = new Event(Expandable.eventNamespace + ':transitionend')
				bodyRef.current.dispatchEvent(toggleEvent)
				bodyRef.current.dispatchEvent(transitionEndEvent)
			}
		}

		const recalculateSizeAndSpeed = () => {
			isOpen = containerRef.current.classList.contains(openClass)

			if (isOpen) {
				bodyRef.current.style.maxHeight = 'none'
			} else {
				recalculateSize()
				recalculateSpeed()
			}

			nTransitions++
		}

		const open = () => toggle(false)
		const close = () => toggle(true)
		const transitionStartHandlerCallback = (e) => transitionStartHandler(e)

		const attachListeners = () => {
			containerRef.current?.addEventListener(Expandable.eventNamespace + ':triggeropen', open)
			containerRef.current?.addEventListener(
				Expandable.eventNamespace + ':triggerclose',
				close
			)
			containerRef.current?.addEventListener(
				Expandable.eventNamespace + ':triggertoggle',
				toggle
			)

			window.addEventListener('resize', recalculateSizeAndSpeed)
			bodyRef.current?.addEventListener('transitionstart', transitionStartHandlerCallback)
			bodyRef.current?.addEventListener('transitionend', transitionEndHandler)
		}

		const clearListeners = () => {
			containerRef.current?.removeEventListener(
				Expandable.eventNamespace + ':triggeropen',
				open
			)
			containerRef.current?.removeEventListener(
				Expandable.eventNamespace + ':triggerclose',
				close
			)
			containerRef.current?.removeEventListener(
				Expandable.eventNamespace + ':triggertoggle',
				toggle
			)

			window.removeEventListener('resize', recalculateSizeAndSpeed)
			bodyRef.current?.removeEventListener('transitionstart', transitionStartHandlerCallback)
			bodyRef.current?.removeEventListener('transitionend', transitionEndHandler)
		}

		/**
		 * Checks if show more button should be visible.
		 */
		const handleShowMoreBtn = () => {
			const elMaxHeight = window.getComputedStyle(bodyRef.current)['maxHeight']

			if (elMaxHeight && elMaxHeight !== 'none' && inner) {
				const currentHeight = elMaxHeight.replace('px', '')
				const actualHeight = innerRef.current.clientHeight

				if (actualHeight > currentHeight) {
					showMoreRef.current.classList.remove('hide')
				}
			}
		}

		useEffect(() => {
			attachListeners()
			handleShowMoreBtn()
			recalculateSizeAndSpeed()

			return clearListeners
		}, [])

		return (
			<div
				className={`ui-expandable ${containerClass} ${initializedClass} ${isOpen ? openClass : ''}`}
				ref={containerRef}
			>
				{headerContent && (
					<div
						className={`${headerSelector} ${headerClass}`}
						onClick={() => {
							if (headerFunction) headerFunction()
							if (!cancelHeaderToggle) toggle()
						}}
					>
						{headerContent}
					</div>
				)}
				<div className={`${bodySelector} ${bodyClass}`} ref={bodyRef}>
					{inner ? (
						<div className={`${innerSelector} ${innerClass}`} ref={innerRef}>
							{children}
						</div>
					) : (
						children
					)}
				</div>
				{showMoreTextLess && showMoreTextMore && (
					<div
						className={`${showMoreSelector} ${showMoreClass}`}
						data-text-more={showMoreTextMore}
						data-text-less={showMoreTextLess}
						ref={showMoreRef}
						onClick={() => {
							if (!cancelHeaderToggle) toggle()
						}}
					/>
				)}
			</div>
		)
	}
)

Expandable.propTypes = {
	isOpen: PropTypes.bool,
	parent: PropTypes.instanceOf(Element),
	children: PropTypes.node,
	inner: PropTypes.bool,
	headerContent: PropTypes.node,
	headerFunction: PropTypes.func,
	showMoreTextLess: PropTypes.string,
	showMoreTextMore: PropTypes.string,
	headerClass: PropTypes.string,
	containerClass: PropTypes.string,
	bodyClass: PropTypes.string,
	showMoreClass: PropTypes.string,
	innerClass: PropTypes.string,
	headerSelector: PropTypes.string,
	bodySelector: PropTypes.string,
	showMoreSelector: PropTypes.string,
	innerSelector: PropTypes.string,
	openClass: PropTypes.string,
	initializedClass: PropTypes.string,
	duration: PropTypes.number,
	speed: PropTypes.number,
	minTime: PropTypes.number,
	maxTime: PropTypes.number,
	cancelHeaderToggle: PropTypes.bool
}

Expandable.eventNamespace = 'ui-expandable'

Expandable.open = (target) => {
	const openEvent = new CustomEvent(Expandable.eventNamespace + ':' + 'triggeropen')
	target.dispatchEvent(openEvent)
}

Expandable.close = (target) => {
	const closeEvent = new CustomEvent(Expandable.eventNamespace + ':' + 'triggerclose')
	target.dispatchEvent(closeEvent)
}

Expandable.toggle = (target) => {
	const toggleEvent = new CustomEvent(Expandable.eventNamespace + ':' + 'triggertoggle')
	target.dispatchEvent(toggleEvent)
}

Expandable.displayName = 'Expandable'

export default Expandable
