import {useEffect, useState} from 'react'

/** useLocalStorage
 * @internal
 *
 */
function useLocalStorage(key) {
	const readValue = () => {
		if (typeof window === 'undefined') {
			return null
		}

		return window.localStorage.getItem(key)
	}

	const [storedValue, setStoredValue] = useState(readValue)

	const handleStorageChange = (event) => {
		if (event.detail !== key) {
			return
		}
		setStoredValue(readValue())
	}

	useEffect(() => {
		document.addEventListener('localStorageChange', handleStorageChange)

		return () => document.removeEventListener('localStorageChange', handleStorageChange)
	}, [])

	return storedValue
}

export default useLocalStorage
