import React from 'react'
import PropTypes from 'prop-types'

const LinkRefinements = ({filter, toggleFilter, selectedFilters}) => {
	return (
		<div>
			<ul>
				{filter.values.map((value) => {
					const isSelected = selectedFilters.includes(value.value)
					return (
						<li className="refinement-category__list" key={value.value}>
							<a
								className={
									'refinement-category__link ' +
									(isSelected && 'refinement-category__link--special')
								}
								onClick={() => toggleFilter(value, filter.name, isSelected)}
							>
								{value.value.slice(0, 1).toUpperCase() +
									value.value.slice(1, value.value.length)}
								<span>({value.hits_count})</span>
							</a>
						</li>
					)
				})}
			</ul>
		</div>
	)
}

LinkRefinements.propTypes = {
	filter: PropTypes.object,
	selectedFilters: PropTypes.array,
	toggleFilter: PropTypes.func
}

export default LinkRefinements
