import React, {useEffect} from 'react'
import AppRoutes from './routes/AppRoutes'
import {createPortal} from 'react-dom'
import Search from './components/search'
import {LayoutProvider} from './contexts/layout'

const suggestionsEl = document.getElementById('suggestions')

const App = () => {
	useEffect(() => {
		const event = new Event('reactLoaded')
		document.dispatchEvent(event)
	}, [])

	return (
		<>
			<AppRoutes />
			<LayoutProvider>
				<>{createPortal(<Search />, suggestionsEl)}</>
			</LayoutProvider>
		</>
	)
}

export default App
