import React, {useState, useEffect} from 'react'
import PropTypes from 'prop-types'
import {useIntl} from 'react-intl'
import CategoryRefinement from './category-refinement'
import {getFoldingModifier} from '../../../utils/search-utils'

const CategoryRefinements = ({filter, toggleFilter, selectedFilters}) => {
	const intl = useIntl()
	const categories = filter?.values
	const initialFoldingValue = 10
	const categoriesLength = categories?.length || 0
	const [foldingCutoff, setFoldingCutoff] = useState(initialFoldingValue)
	const shouldBeFolded = categoriesLength > foldingCutoff
	const [isFolded, setFolded] = useState(shouldBeFolded)
	const foldingTreshold = isFolded ? categoriesLength - foldingCutoff : 0

	const updateSelection = () => {
		setFolded(shouldBeFolded)
	}

	useEffect(() => {
		setFolded(shouldBeFolded)
	}, [shouldBeFolded])

	useEffect(() => {
		const refreshedFoldingModifier = getFoldingModifier(
			categories,
			selectedFilters,
			initialFoldingValue,
			1
		)
		setFoldingCutoff(initialFoldingValue + refreshedFoldingModifier)
	}, [selectedFilters])

	return (
		categories && (
			<div className="refinement-multi-categories">
				{categories.map((category, index) => {
					return (
						<CategoryRefinement
							category={category}
							toggleFilter={toggleFilter}
							selectedFilters={selectedFilters}
							key={category?.value + index}
							iteration={1}
							parentSelectionUpdate={updateSelection}
							hide={isFolded && index + 1 > initialFoldingValue}
						/>
					)
				})}

				{isFolded && (
					<div
						className="refinement-multi-categories__show-all"
						onClick={() => setFolded(!isFolded)}
					>
						{intl.formatMessage({
							id: 'search.search.show.more'
						})}
						{' (' + foldingTreshold + ')'}
					</div>
				)}
			</div>
		)
	)
}

CategoryRefinements.propTypes = {
	filter: PropTypes.object,
	selectedFilters: PropTypes.array,
	toggleFilter: PropTypes.func
}

export default CategoryRefinements
