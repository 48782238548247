import React from 'react'
import {useIntl} from 'react-intl'
import PropTypes from 'prop-types'
import Icon from '../../../components/icon'
import {useCurrency} from '../../../hooks/use-currency'
import {getFacetIDByDisplayName, extractRatingNumber} from '../../../utils/refinement-utils'

const SelectedRefinements = ({toggleFilter, selectedFilterValues, handleReset}) => {
	const intl = useIntl()
	const {currency} = useCurrency()

	let selectedFilters = []

	const parseFilter = (keyString, filter) => {
		if (keyString === 'price') {
			return filter.split('|').map(
				(val, index) =>
					intl.formatNumber(val, {
						style: 'currency',
						currency: currency
					}) + (index == 0 ? ' - ' : '')
			)
		}

		if (keyString === 'category') {
			return filter.replace(/\|\|/g, ' > ')
		}

		if (keyString === 'trustmateAverageGrade') {
			filter = extractRatingNumber(filter)
			return intl.formatMessage({
				id: 'reviews.review.stars' + (filter - 1)
			})
		}

		if (filter === 'true') {
			return keyString
		}

		return filter
	}

	selectedFilterValues?.forEach((item) => {
		const split = item.split(':')
		const filter = split[1]
		const keyString = split[0]

		if (filter == 'item' || keyString == 'searchable') {
			return
		}

		var refinementID = getFacetIDByDisplayName(keyString) || keyString

		const selected = {
			uiLabel: parseFilter(refinementID, filter),
			value: refinementID,
			apiLabel: filter
		}

		selectedFilters.push(selected)
	})

	return (
		<React.Fragment>
			{selectedFilters?.length > 0 && (
				<div className="category-controls__refinements">
					<div className="refinement-pill">
						<div className="refinement-pill__row">
							{selectedFilters?.map((filter, idx) => {
								return (
									<div
										className="refinement-pill__item"
										title={intl.formatMessage({
											id: 'locale.global.remove.refine'
										})}
										key={idx}
										onClick={() =>
											toggleFilter(
												{value: filter.apiLabel},
												filter.value,
												true
											)
										}
									>
										<span className="refinement-pill__value">
											{filter.uiLabel}
										</span>
										<div className="refinement-pill__icon">
											<Icon id={'icon-closethick'} />
										</div>
									</div>
								)
							})}
						</div>
						<div className="refinement-pill__clear-wrap">
							<div className="refinement-pill__clear-text">
								{intl.formatMessage({
									id: 'search.searchrefinebar.used.filters.label'
								})}
							</div>

							<div className="refinement-pill__clear">
								<span className="link link--hover" onClick={handleReset}>
									<Icon
										className={'refinement-pill__clear-icon'}
										id={'icon-closethick'}
									/>
									{intl.formatMessage({id: 'locale.global.clear.filters'})}
								</span>
							</div>
						</div>
					</div>
				</div>
			)}
		</React.Fragment>
	)
}

SelectedRefinements.propTypes = {
	filters: PropTypes.array,
	selectedFilterValues: PropTypes.array,
	toggleFilter: PropTypes.func,
	handleReset: PropTypes.func
}

export default SelectedRefinements
