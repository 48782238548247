import React from 'react'
import ReactDOM from 'react-dom/client'
import {BrowserRouter} from 'react-router-dom'
import {QueryClient, QueryClientProvider} from '@tanstack/react-query'
import {ReactQueryDevtools} from '@tanstack/react-query-devtools'
import {getConfig} from './config/config'
import {createUrlTemplate} from './utils/url'
import {MultiSiteProvider, Translations, CurrencyProvider} from './contexts'

import {resolveSiteFromUrl, resolveLocaleFromUrl} from './utils/site-utils'

import App from './App'

const root = ReactDOM.createRoot(
	document.getElementById('root') || document.getElementById('suggestions')
)
const queryClient = new QueryClient({
	defaultOptions: {
		queries: {
			retry: false,
			refetchOnWindowFocus: false,
			staleTime: 10 * (60 * 1000),
			cacheTime: 15 * (60 * 1000)
		},
		mutations: {
			retry: false
		}
	}
})
const path = `${window.location.pathname}${window.location.search}`
const site = resolveSiteFromUrl(path)
const locale = resolveLocaleFromUrl(path)

const {app: appConfig} = getConfig()
var locals = {}
locals.buildUrl = createUrlTemplate(appConfig, site.alias || site.id, locale.id)
locals.site = site
locals.locale = locale
locals.appConfig = appConfig

const {l10n} = site
const currency = locale.preferredCurrency || l10n.defaultCurrency

root.render(
	<React.StrictMode>
		<BrowserRouter>
			<MultiSiteProvider site={locals.site} locale={locals.locale} buildUrl={locals.buildUrl}>
				<QueryClientProvider client={queryClient}>
					<Translations>
						<CurrencyProvider currency={currency}>
							<App />
						</CurrencyProvider>
					</Translations>
					<ReactQueryDevtools initialIsOpen={false} />
				</QueryClientProvider>
			</MultiSiteProvider>
		</BrowserRouter>
	</React.StrictMode>
)
