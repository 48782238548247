import React from 'react'
import PropTypes from 'prop-types'
import {useIntl} from 'react-intl'
import ProductSuggestion from './product-suggestion'
import useProductsSfccGeneral from '../../../hooks/use-products-sfcc-general'

const ProductSuggestions = ({suggestions}) => {
	const intl = useIntl()

	const productIDs = suggestions?.map((el) => {
		return el.attributes.ID[0]
	})

	const {data: productsSfccGeneral} = useProductsSfccGeneral(productIDs)

	return (
		productsSfccGeneral && (
			<div className="product-suggestions">
				<p className="product-suggestions__header">
					{intl.formatMessage({id: 'search.search.producthits.productssuggestions'})}
				</p>
				<div className="product-suggestions__list">
					{suggestions.map((suggestion, index) => (
						<ProductSuggestion
							product={suggestion}
							productsSfccGeneral={productsSfccGeneral?.[index]}
							key={index}
						/>
					))}
				</div>
			</div>
		)
	)
}

ProductSuggestions.propTypes = {
	suggestions: PropTypes.array
}

export default ProductSuggestions
