import React from 'react'
import PropTypes from 'prop-types'
import Icon from '../../../components/icon'
import {useIntl} from 'react-intl'

const WishlistBadge = ({isFavourite, id}) => {
	const intl = useIntl()

	const triggerAddToWishlistEvent = (e) => {
		e.preventDefault()
		const customEvent = new CustomEvent('addToWishlistEvent', {
			detail: e.target
		})
		document.dispatchEvent(customEvent)
	}

	const triggerRemoveFromWishlistEvent = (e) => {
		e.preventDefault()
		const customEvent = new CustomEvent('removeToWishlistEvent', {
			detail: e.target
		})
		document.dispatchEvent(customEvent)
	}

	return (
		<div
			className="wishlist-icon wishlist-icon--tile"
			data-pid={id}
			data-wishlist-context="tile"
		>
			{isFavourite ? (
				<span
					onClick={triggerRemoveFromWishlistEvent}
					className="wishlist-icon__remove wishlist-icon__remove--visible"
					title={intl.formatMessage({id: 'product.product.removefromwishlist'})}
				>
					<Icon
						className={'wishlist-icon__svg wishlist-icon__svg--full'}
						id={'icon-heard-full'}
					/>
				</span>
			) : (
				<span
					onClick={triggerAddToWishlistEvent}
					className="wishlist-icon__add wishlist-icon__add--visible"
					title={intl.formatMessage({id: 'product.product.addtowishlist'})}
				>
					<Icon
						className={'wishlist-icon__svg wishlist-icon__svg--empty'}
						id={'icon-heard'}
					/>
				</span>
			)}
		</div>
	)
}

WishlistBadge.propTypes = {
	isFavourite: PropTypes.bool,
	id: PropTypes.string
}

export default WishlistBadge
