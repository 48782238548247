import React from 'react'
import PropTypes from 'prop-types'
import {useIntl} from 'react-intl'
import DynamicImage from '../../../components/dynamic-image'

const TileImage = ({image, hoverImage, alt, showHoverImage}) => {
	const intl = useIntl()

	return (
		<div className="product-tile__image-background">
			<div className="tile-image">
				<div className="tile-image__overlay">
					<span className="tile-image__text tile-image__text--small">
						{intl.formatMessage({id: 'product.product.unavailable.tile.text.part1'})}
					</span>
					<span className="tile-image__text tile-image__text--big">
						{intl.formatMessage({id: 'product.product.unavailable.tile.text.part2'})}
					</span>
				</div>

				<div className="thumb-link">
					{showHoverImage && hoverImage && (
						<DynamicImage
							src={hoverImage}
							className={'tile-image__hover tile-image__hover--loaded'}
							imageType={'standardHero'}
							alt={alt + '2'}
							isLazyLoad={false}
						/>
					)}

					<DynamicImage
						src={image}
						className={'tile-image__container'}
						imageType={'standardHero'}
						alt={alt + '1'}
						isLazyLoad={true}
					/>

					<div className="tile-image__stub"></div>
				</div>
			</div>
		</div>
	)
}

TileImage.propTypes = {
	alt: PropTypes.string,
	image: PropTypes.string,
	hoverImage: PropTypes.string,
	showHoverImage: PropTypes.bool
}

export default TileImage
