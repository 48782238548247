import React from 'react'
import PropTypes from 'prop-types'

const BadgeAsset = ({asset}) => {
	return <div dangerouslySetInnerHTML={{__html: asset}} />
}

BadgeAsset.propTypes = {
	asset: PropTypes.string
}

export default BadgeAsset
