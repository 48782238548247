import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import {dis} from '../../config/dis'
import {breakpoints} from '../../config/breakpoints'
import LazyLoad from 'vanilla-lazyload'
import lazyloadConfig from '../../config/lazyload'

// Only initialize it one time for the entire application
if (!document.lazyLoadInstance) {
	document.lazyLoadInstance = new LazyLoad(lazyloadConfig)
}

const imageViewType = (imageType) => {
	var imageTypeMap = {
		standardTile: ['PLP_mobile', 'PLP_tablet', 'PLP_desktop'],
		standardHero: [
			'PLP_mobile_hero_standard',
			'PLP_tablet_hero_standard',
			'PLP_desktop_hero_standard'
		],
		quickview: ['QUICKVIEW_mobile', 'QUICKVIEW_tablet', 'QUICKVIEW_desktop']
	}

	return !imageTypeMap[imageType] ? imageTypeMap[imageType] : imageTypeMap.standardTile
}

const mediasMapping = () => {
	return {
		PDP_mobile: breakpoints['bp-min'],
		PDP_tablet: breakpoints['bp-small-medium'],
		PDP_desktop: breakpoints['bp-medium'],
		PDP_gallery_mobile: breakpoints['bp-min'],
		PDP_gallery_tablet: breakpoints['bp-small-medium'],
		PDP_gallery_desktop: breakpoints['bp-medium'],
		PLP_mobile: breakpoints['bp-min'],
		PLP_mobile_hero_standard: breakpoints['bp-min'],
		PLP_tablet: breakpoints['bp-small-medium'],
		PLP_tablet_hero_standard: breakpoints['bp-small-medium'],
		PLP_desktop: breakpoints['bp-medium'],
		PLP_desktop_hero_standard: breakpoints['bp-medium'],
		icon: breakpoints['bp-min']
	}
}

const disParamsMap = {
	scaleWidth: 'sw',
	scaleHeight: 'sh',
	scaleMode: 'sm'
}

const urlParams = (params) => {
	return Object.keys(params)
		.map((key) => `${disParamsMap[key]}=${encodeURIComponent(params[key])}`)
		.join('&')
}

const DynamicImage = ({src, alt, className, imageType, isLazyLoad}) => {
	// Update lazyLoad after first rendering of every image
	useEffect(() => {
		document.lazyLoadInstance.update()
	}, [])

	// Update lazyLoad after rerendering of every image
	useEffect(() => {
		document.lazyLoadInstance.update()
	})

	const viewTypes = imageViewType(imageType)
	const mediasMapped = mediasMapping()
	const set = {}

	if (viewTypes) {
		viewTypes.map((viewType) => {
			let disConf = dis[viewType]
			let params = urlParams(disConf)
			let media = mediasMapped[viewType] || 0

			set[media] = src + `?${params}`
		})
	} else {
		let disConf = dis[imageType]
		let params = urlParams(disConf)
		let media = mediasMapped[imageType] || 0

		set[media] = src + `?${params}`
	}

	const mediasSorted = Object.keys(set).sort((a, b) => b - a)

	const imageClass = isLazyLoad ? 'lazy' : ''
	const imageSrc = isLazyLoad ? 'data-src' : 'src'
	const sourceSrcset = isLazyLoad ? 'data-srcset' : 'srcSet'

	return (
		<picture className={className}>
			{mediasSorted.map((media, index) => {
				return (
					<React.Fragment key={index}>
						<source
							key={index}
							media={'(min-width:' + media + 'px)'}
							{...{[sourceSrcset]: set[media]}}
						/>
					</React.Fragment>
				)
			})}

			<img className={imageClass} {...{[imageSrc]: set[0]}} alt={alt} />
		</picture>
	)
}

DynamicImage.propTypes = {
	src: PropTypes.string,
	alt: PropTypes.string,
	className: PropTypes.string,
	imageType: PropTypes.string,
	isLazyLoad: PropTypes.bool
}

export default DynamicImage
