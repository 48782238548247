import {getProductSearch} from '../services/lbx-service'
import {useQuery} from '@tanstack/react-query'

const useProductSearch = ({parameters}, keepPreviousData) => {
	const {data, isRefetching, isLoading} = useQuery({
		queryKey: ['product-search', parameters],
		queryFn: () => {
			return getProductSearch(parameters)
		},
		keepPreviousData
	})

	return {isLoading, isRefetching, data}
}

export default useProductSearch
