import {useEffect, useState} from 'react'
import {getSuggestions} from '../services/lbx-service'
import {formatSuggestions} from '../utils/search-utils'
import {RECENT_SEARCH_MIN_LENGTH} from '../constants'

const useSearchSuggestions = (searchQuery) => {
	const enabled = searchQuery?.length >= RECENT_SEARCH_MIN_LENGTH
	const [suggestionResult, setSuggestionResult] = useState({})

	useEffect(() => {
		if (!enabled || !searchQuery) {
			setSuggestionResult({})
			return
		}

		const fetchSearch = async () => {
			const suggestions = await getSuggestions(searchQuery)
			const formattedResult = formatSuggestions(suggestions, searchQuery)
			setSuggestionResult(formattedResult)
		}

		fetchSearch()
	}, [searchQuery, enabled])

	return suggestionResult
}

export default useSearchSuggestions
