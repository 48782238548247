import React from 'react'
import PropTypes from 'prop-types'
import {useIntl} from 'react-intl'
import {useCurrency} from '../../../hooks/use-currency'
import {sortPriceRefinements} from '../../../utils/refinement-utils'

const PriceRefinements = ({filter, toggleFilter, selectedFilters}) => {
	const intl = useIntl()
	const {currency} = useCurrency()

	const selectedFiltersFormatted = selectedFilters.join('|')
	const priceValues = sortPriceRefinements(filter?.values)

	const filterName = (value) => {
		return value.split('|').map(
			(val, index) =>
				intl.formatNumber(val, {
					style: 'currency',
					currency: currency
				}) + (index == 0 ? ' - ' : '')
		)
	}

	return (
		<div className="refinement-price">
			<ul>
				{priceValues
					.filter((refinementValue) => refinementValue.hits_count > 0)
					.map((value) => {
						const isChecked = selectedFiltersFormatted.includes(value.value)

						return (
							<li
								key={value.value}
								className="refinement-price__list"
								data-gtm-value={filterName(value.value)}
							>
								<a className="refinement-link">
									<input
										className="input-radio"
										type="radio"
										name="price"
										value={value.value}
										id={value.value}
										checked={isChecked}
										onChange={() => {
											toggleFilter(
												value,
												'price',
												selectedFiltersFormatted.includes(value.value)
											)
										}}
									/>
									<label className="radio-label" htmlFor={value.value}>
										{filterName(value.value)}
										<span>({value.hits_count})</span>
									</label>
								</a>
							</li>
						)
					})}
			</ul>
		</div>
	)
}

PriceRefinements.propTypes = {
	filter: PropTypes.object,
	toggleFilter: PropTypes.func,
	selectedFilters: PropTypes.array
}

export default PriceRefinements
