import React from 'react'
import PropTypes from 'prop-types'

const Icon = ({className, id, onClick}) => {
	if (!id) {
		return null
	}

	const href = process.env.NODE_ENV === 'development' ? `/images/sprites.svg#${id}` : `#${id}`

	return (
		<svg className={className} onClick={onClick}>
			<use href={href}></use>
		</svg>
	)
}

Icon.propTypes = {
	className: PropTypes.string,
	id: PropTypes.string,
	onClick: PropTypes.func
}

export default Icon
