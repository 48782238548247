import React, {useRef} from 'react'
import PropTypes from 'prop-types'

const QuantityButtons = ({
	isDelayed,
	cartInfo,
	productAvailability,
	currentQty,
	setInputValue,
	maxQty,
	minQty
}) => {
	const input = useRef(null)

	const handleIncrease = (triggerUpdateCall) => {
		let val = currentQty + 1
		let prevQty = currentQty

		if (currentQty >= maxQty) {
			val = maxQty
		}

		setInputValue(val)

		if (triggerUpdateCall) {
			triggerQtyUpdateEvent(val, prevQty)
		}
	}

	const handleDecrease = (triggerUpdateCall) => {
		let val = currentQty - 1
		let prevQty = currentQty

		if (currentQty <= minQty) {
			val = minQty
		}

		setInputValue(val)

		if (triggerUpdateCall !== false) {
			triggerQtyUpdateEvent(val, prevQty)
		}
	}

	const minicartChangeQuantity = (e) => {
		if (!e || !e.detail) {
			return
		}

		if (e.detail == 'cart-remove') {
			handleDecrease(false)
		} else {
			handleIncrease(false)
		}
	}

	document.addEventListener('minicartChangeQuantity', minicartChangeQuantity)

	const triggerQtyUpdateEvent = (qty, prevQty) => {
		const data = {
			pid: productAvailability?.pid,
			lineItemUUID: cartInfo.UUID,
			qty: qty,
			module: 'minicart',
			difference: qty - prevQty
		}

		setTimeout(() => {
			if (input.current.value != qty) {
				return
			}

			const customEvent = new CustomEvent('updateLineItemQty', {
				detail: {
					input: input,
					data: data
				}
			})
			document.dispatchEvent(customEvent)
		}, 1000)
	}

	return (
		<React.Fragment>
			{!isDelayed && cartInfo && (
				<div
					className="added-to-cart quantity-buttons-tile"
					data-pid={productAvailability?.pid}
				>
					<span
						onClick={handleDecrease}
						className={
							'quantity-buttons-tile__select ' +
							(currentQty <= 1 && 'quantity-buttons--gray')
						}
					>
						<span className="quantity-buttons__icon-minus"></span>
					</span>

					<input
						type="number"
						min={minQty}
						max={maxQty}
						data-pid={productAvailability?.pid}
						data-id={productAvailability?.pid}
						data-uuid={cartInfo.UUID}
						data-gtm-val={currentQty}
						data-val={currentQty}
						value={currentQty}
						className="input-text quantity-buttons-tile__value-input hide"
						ref={input}
					/>

					<span className="quantity quantity-value quantity-buttons-tile__value">
						{currentQty}
					</span>

					<span
						onClick={handleIncrease}
						className={
							'quantity-buttons-tile__select ' +
							(currentQty == maxQty && 'quantity-buttons-tile--gray')
						}
					>
						<span className="quantity-buttons__icon-plus"></span>
					</span>
				</div>
			)}
		</React.Fragment>
	)
}

QuantityButtons.propTypes = {
	isDelayed: PropTypes.bool,
	cartInfo: PropTypes.object,
	productAvailability: PropTypes.object,
	currentQty: PropTypes.string,
	setInputValue: PropTypes.func,
	maxQty: PropTypes.number,
	minQty: PropTypes.number
}

export default QuantityButtons
