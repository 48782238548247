import React, {useReducer, useContext} from 'react'
import PropTypes from 'prop-types'
import Layout from '../components/layout'

const LayoutContext = React.createContext(false)
const LayoutDispatchContext = React.createContext(false)

export function LayoutProvider({children}) {
	const [state, dispatch] = useReducer(stateReducer, false)

	return (
		<LayoutContext.Provider value={state}>
			<LayoutDispatchContext.Provider value={dispatch}>
				<Layout state={state}>{children}</Layout>
			</LayoutDispatchContext.Provider>
		</LayoutContext.Provider>
	)
}

export function useLayoutState() {
	return useContext(LayoutContext)
}

export function useLayoutStateDispatch() {
	return useContext(LayoutDispatchContext)
}

export function stateReducer(state, change) {
	state = change
	return state
}

LayoutProvider.propTypes = {
	children: PropTypes.node
}

export default {LayoutProvider}
