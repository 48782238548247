import {useCallback} from 'react'

const listener = () => {
	window.ScreenLocker.corruptedScroll = false
	window.removeEventListener('scroll', listener)
}

/**
 * Custom React hook to get the function that adds ScreenLocker functionality which allows to prevent not necessary user interactions
 * @returns {{changeState: (function(Boolean): *)}} changeState - Function that changes the screen lock state
 */
export const useScreenLocker = () => {
	window.ScreenLocker = window.ScreenLocker || {}
	const target = document.body
	const lockedClassName = 'ui-screen-locked'
	const isLocked = () => {
		return target.classList.contains(lockedClassName)
	}

	const changeState = useCallback(
		(state) => {
			const hadLock = isLocked()
			const offset = -(hadLock ? target.style.top.slice(0, -2) : window.scrollY)
			let scrollWidth = 0

			window.ScreenLocker.corruptedScroll = true
			window.addEventListener('scroll', listener)

			if (!hadLock && state) {
				target.style.top = offset
				scrollWidth = window.innerWidth - document.body.clientWidth
				target.style['padding-right'] = scrollWidth
			}

			target.classList[state ? 'add' : 'remove'](lockedClassName)

			const event = new CustomEvent('screenlocker:change', {detail: state})

			document.dispatchEvent(event)

			if (hadLock && !state) {
				target.style.top = offset
				scrollWidth = 0
				target.style['padding-right'] = scrollWidth
			}

			document.documentElement.style.setProperty('--scroll-width', scrollWidth + 'px')
		},
		[target]
	)

	return {changeState}
}

export default useScreenLocker
