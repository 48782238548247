import React from 'react'
import PropTypes from 'prop-types'
import {useIntl} from 'react-intl'
import useMediaQuery from '../../../hooks/use-media-query'
import {selectedCategoryRefinementsList} from '../../../utils/refinement-utils'
import {getFacetIDByDisplayName} from '../../../utils/refinement-utils'
import Expandable from '../../../components/expandable'

const Refinement = ({
	children,
	filter,
	selectedFilters,
	setOpenSecondaryPopUp,
	setRefinement,
	refinementsContainer
}) => {
	const intl = useIntl()
	const isMobile = useMediaQuery('screen and (max-width: 1023px)')

	if (!filter.values.length) {
		return
	}

	const toggleClass = (filterID) => {
		if (isMobile) {
			setOpenSecondaryPopUp(true)
			setRefinement(filterID)
		}
	}

	const selectedRefinementNumber = (filterID) => {
		if (filterID == 'price') {
			return selectedFiltersNumber / 2
		}

		if (filterID == 'category') {
			return selectedCategoryRefinementsList(selectedFilters).length
		}

		return selectedFiltersNumber
	}

	const selectedFiltersNumber = selectedFilters.length
	const openRefinement = selectedFiltersNumber > 0 || filter.name == 'custom'
	const filterName = filter.name
	const filterID = getFacetIDByDisplayName(filter.name) || 'custom'

	return (
		<React.Fragment>
			{filterID !== 'category' || isMobile ? (
				<Expandable
					isOpen={openRefinement && !isMobile}
					parent={refinementsContainer}
					containerClass={'refinement refinement-' + filterID}
					headerClass={'refinement__title'}
					headerContent={
						<>
							<span className="refinement__label">
								<span className="js-gtm-ref-name">
									{intl.formatMessage({
										id: 'search.search.refinement.title.' + filterID
									})}
								</span>
								{selectedFiltersNumber > 0 && (
									<span className="refinement__number">
										{selectedRefinementNumber(filterID)}
									</span>
								)}
							</span>
							<span className="refinement__title-arrow"></span>
						</>
					}
					headerFunction={() => toggleClass(filterID)}
					cancelHeaderToggle={isMobile}
				>
					{children}
				</Expandable>
			) : (
				<div className={'refinement mb-40 refinement-' + filterName}>{children}</div>
			)}
		</React.Fragment>
	)
}

Refinement.propTypes = {
	filter: PropTypes.object,
	selectedFilters: PropTypes.array,
	children: PropTypes.node,
	setOpenSecondaryPopUp: PropTypes.func,
	setRefinement: PropTypes.func,
	refinementsContainer: PropTypes.instanceOf(Element)
}

export default Refinement
