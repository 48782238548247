import React from 'react'
import PropTypes from 'prop-types'
import {useIntl} from 'react-intl'

const StarRating = ({trustmateAverageGrade, trustmateReviewsCount, showCount, modifier}) => {
	const intl = useIntl()

	if (!window.ReactPreferences.REVIEWS_ENABLED) {
		return
	}

	const isTile = modifier === 'tile'
	const classModifier = 'reviews-stars--' + modifier

	if (trustmateAverageGrade > 0) {
		return (
			<React.Fragment>
				<div className={'reviews-stars ' + classModifier}>
					<div className="reviews-stars__star">
						<span
							className="reviews-stars__value"
							style={{width: (trustmateAverageGrade / 5) * 100 + '%'}}
						></span>
					</div>

					{showCount && trustmateReviewsCount > 0 && (
						<div className="reviews-stars__count">({trustmateReviewsCount})</div>
					)}
				</div>
			</React.Fragment>
		)
	} else if (isTile) {
		return (
			<React.Fragment>
				<div className="reviews-stars__no-reviews">
					{intl.formatMessage({id: 'reviews.review.no.reviews'})}
				</div>
			</React.Fragment>
		)
	}
}

StarRating.propTypes = {
	trustmateAverageGrade: PropTypes.number,
	trustmateReviewsCount: PropTypes.number,
	showCount: PropTypes.bool,
	modifier: PropTypes.string
}

export default StarRating
