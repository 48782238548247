import {useEffect, useState} from 'react'
import useLocalStorage from '../hooks/use-local-storage'

export const useWishList = () => {
	const [wishlistArr, setWishlistArr] = useState([])

	const wishlistLocal = useLocalStorage('wishlistLocal')

	useEffect(() => {
		if (!wishlistLocal) {
			return
		}

		const {wishlistArr: storedWishlistArr} = JSON.parse(wishlistLocal)
		setWishlistArr(storedWishlistArr)
	}, [wishlistLocal])

	return wishlistArr
}
