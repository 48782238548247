import React from 'react'
import PropTypes from 'prop-types'
import {useIntl} from 'react-intl'
import {useCurrency} from '../../hooks/use-currency'
import Icon from '../icon'
import Tooltip from '../tooltip'

const OmnibusPricing = ({priceClass, omnibusPrice, assets}) => {
	const intl = useIntl()
	const {currency} = useCurrency()
	const isOrderInfo = priceClass === 'cart' || priceClass === 'set'
	const hideTooltips = priceClass === 'suggestion' || priceClass === 'minicart' || isOrderInfo
	const {omnibusAssetLowest, omnibusAssetLowestTooltip} = assets

	return (
		<div className={'price-omnibus-text price-omnibus-text--' + priceClass}>
			{omnibusAssetLowest && omnibusPrice && omnibusPrice > 0 && (
				<div className="price-omnibus-text__wrapper">
					<span className="price-omnibus-text__value">
						{intl.formatNumber(omnibusPrice, {
							style: 'currency',
							currency: currency
						})}
					</span>
					<span className="price-omnibus-text__copy-text">{omnibusAssetLowest}</span>
					{omnibusAssetLowestTooltip && !hideTooltips && (
						<Tooltip
							triggerClass={'tooltip-trigger--info tooltip-trigger--pricing'}
							tooltipClass={'tooltip--pricing'}
							triggerContent={<Icon id={'icon-info'} />}
							arrow={false}
							place={'top'}
							spacing={4}
							clickOnly={true}
						>
							{omnibusAssetLowestTooltip}
						</Tooltip>
					)}
				</div>
			)}
		</div>
	)
}

OmnibusPricing.propTypes = {
	priceClass: PropTypes.string,
	omnibusPrice: PropTypes.number,
	assets: PropTypes.object
}

export default OmnibusPricing
