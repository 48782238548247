import React from 'react'
import PropTypes from 'prop-types'
import BadgeAsset from './badge-asset'

const Badge = ({badge}) => {
	const {
		verticalBadges,
		everyonePromotionBadge: everyoneBadge,
		horizontalBadges,
		page
	} = badge ?? {}
	const isEveryoneEmpty = !everyoneBadge || Object.keys(everyoneBadge).length == 0

	const getEveryoneBadge = () => {
		let badge
		const badgeImage = everyoneBadge.promotionBadgeImageUrl
		const badgeText = everyoneBadge.promotionBadge

		if (badgeImage) {
			badge = <img src={badgeImage} alt={everyoneBadge.calloutMsg} />
		} else if (badgeText) {
			badge = <span>{badgeText}</span>
		} else {
			badge = <BadgeAsset asset={everyoneBadge.asset} />
		}

		return badge
	}

	const buildBadgeClassName = () => {
		let className = 'badge-' + page + '__item '

		if (everyoneBadge.promotionBadge && !everyoneBadge.promotionBadgeImageUrl) {
			className += 'badge-' + page + '__item--text'
		}

		return className
	}

	return (
		<React.Fragment>
			{(verticalBadges?.length > 0 || !isEveryoneEmpty) && (
				<div className={'badge-' + page}>
					<div className={'badge-' + page + '__box'}>
						{!isEveryoneEmpty && (
							<div
								data-promotion={everyoneBadge.ID}
								className={buildBadgeClassName()}
							>
								{getEveryoneBadge()}
							</div>
						)}

						{verticalBadges.map((verticalBadge) => {
							return (
								<div className={'badge-' + page + '__item'} key={verticalBadge}>
									<BadgeAsset asset={verticalBadge} />
								</div>
							)
						})}
					</div>
				</div>
			)}
			{horizontalBadges && (
				<div className={'badge-horizontal-' + page}>
					<div className={'badge-horizontal-' + page + '__box'}>
						{horizontalBadges.map((horizontalBadge) => {
							return (
								<div
									className={'badge-horizontal-' + page + '__item'}
									key={horizontalBadge}
								>
									<BadgeAsset asset={horizontalBadge} />
								</div>
							)
						})}
					</div>
				</div>
			)}
		</React.Fragment>
	)
}

Badge.propTypes = {
	badge: PropTypes.object
}

export default Badge
