import React, {useEffect, useState, useRef} from 'react'
import PropTypes from 'prop-types'
import {useIntl} from 'react-intl'
import Expandable from '../../../components/expandable'
import {getFoldingModifier, isCategoryRefActive} from '../../../utils/search-utils'

const CategoryRefinement = ({
	category,
	toggleFilter,
	selectedFilters,
	subcategoryValue,
	parentSelectionUpdate,
	iteration,
	hide
}) => {
	const intl = useIntl()
	const maxCategoryLevel = window?.ReactPreferences?.LBX_CATEGORY_MAX_LEVEL
	const subCategories = category?.children
	const subcategoriesLength = subCategories?.length || 0
	const currentIteration = iteration
	iteration++
	const displaySubcategories = subcategoriesLength > 0 && iteration <= maxCategoryLevel
	const refValue = subcategoryValue ? {value: subcategoryValue} : category
	const initialFoldingValue = 5
	const [foldingCutoff, setFoldingCutoff] = useState(initialFoldingValue)
	const shouldBeFolded = subcategoriesLength > foldingCutoff
	const [isFolded, setFolded] = useState(shouldBeFolded)
	const value = subcategoryValue || category?.value
	const alreadySelected = isCategoryRefActive(value, selectedFilters, currentIteration)
	const [isSelected, setSelected] = useState(alreadySelected)
	const foldingTreshold = isFolded ? subcategoriesLength - foldingCutoff : 0
	const childRef = useRef()

	const handleSize = () => {
		const containerEl = childRef?.current?.containerRef?.current
		const classFlag = containerEl?.classList?.contains('opened')

		if (alreadySelected == classFlag || classFlag == undefined) {
			return
		}

		return childRef?.current?.toggle(!alreadySelected)
	}

	const updateSelection = (state) => {
		setSelected(state)

		if (state) {
			setFolded(shouldBeFolded)
		}

		if (parentSelectionUpdate) {
			parentSelectionUpdate(state)
		}
	}

	useEffect(() => {
		setSelected(alreadySelected)
		handleSize()
	}, [alreadySelected])

	if (isSelected != alreadySelected) {
		setSelected(alreadySelected)
	}

	useEffect(() => {
		if (subcategoryValue && isSelected) {
			parentSelectionUpdate(isSelected)
		}

		if (alreadySelected) childRef?.current?.setInitiallyOpenState()
	}, [])

	useEffect(() => {
		const refreshedFoldingModifier = getFoldingModifier(
			subCategories,
			selectedFilters,
			initialFoldingValue,
			iteration
		)
		setFoldingCutoff(initialFoldingValue + refreshedFoldingModifier)
	}, [selectedFilters])

	useEffect(() => {
		setFolded(shouldBeFolded)
	}, [shouldBeFolded])

	return (
		currentIteration <= maxCategoryLevel && (
			<>
				{displaySubcategories ? (
					<Expandable
						ref={childRef}
						containerClass={
							'refinement-multi-categories__item ' +
							(hide && !isSelected && ' refinement-multi-categories__item--hidden ')
						}
						headerClass={'refinement-multi-categories__title'}
						cancelHeaderToggle={true}
						setOpen={alreadySelected}
						headerContent={
							<>
								<a
									onClick={(e) => {
										e.preventDefault()
										updateSelection(!isSelected)
										toggleFilter(refValue, 'category', isSelected)
										childRef?.current?.toggle(!isSelected)
									}}
								>
									<input
										className="input-checkbox"
										id={value}
										type="checkbox"
										checked={isSelected}
										onChange={(e) => e.preventDefault()}
									/>

									<label className="checkbox-label" htmlFor={value}>
										{category?.value}

										<span className="refinement-multi-categories__count">
											({category?.hits_count})
										</span>
									</label>
								</a>
								<div
									className="refinement-multi-categories__clickable"
									onClick={() => childRef?.current?.toggle()}
								>
									<div className="refinement-multi-categories__arrow"></div>
								</div>
							</>
						}
						bodyClass="refinement-multi-categories__items"
					>
						<>
							{subCategories.map((subCategory, index) => {
								const subcategoryValue = value + '||' + subCategory.value

								return (
									<CategoryRefinement
										category={subCategory}
										toggleFilter={toggleFilter}
										selectedFilters={selectedFilters}
										subcategoryValue={subcategoryValue}
										parentSelectionUpdate={updateSelection}
										iteration={iteration}
										key={value + index}
										hide={isFolded && index + 1 > initialFoldingValue}
									/>
								)
							})}

							{isFolded && (
								<div
									className="refinement-multi-categories__show-all"
									onClick={() => {
										setFolded(!isFolded)
									}}
								>
									{intl.formatMessage({
										id: 'search.search.show.more'
									})}
									{' (' + foldingTreshold + ')'}
								</div>
							)}
						</>
					</Expandable>
				) : (
					<div
						className={
							'refinement-multi-categories__item ' +
							(hide && !isSelected && ' refinement-multi-categories__item--hidden ')
						}
					>
						<div className={'refinement-multi-categories__title'}>
							<a
								onClick={(e) => {
									e.preventDefault()
									updateSelection(!isSelected)
									toggleFilter(refValue, 'category', isSelected)
								}}
							>
								<input
									className="input-checkbox"
									id={value}
									type="checkbox"
									checked={isSelected}
									onChange={(e) => e.preventDefault()}
								/>

								<label className="checkbox-label" htmlFor={value}>
									{category?.value}

									<span className="refinement-multi-categories__count">
										({category?.hits_count})
									</span>
								</label>
							</a>
						</div>
					</div>
				)}
			</>
		)
	)
}

CategoryRefinement.propTypes = {
	category: PropTypes.object,
	alreadySelected: PropTypes.bool,
	subcategoryValue: PropTypes.string,
	selectedFilters: PropTypes.array,
	toggleFilter: PropTypes.func,
	iteration: PropTypes.number,
	parentSelectionUpdate: PropTypes.func,
	hide: PropTypes.bool
}

export default CategoryRefinement
