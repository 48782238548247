import {useQuery} from '@tanstack/react-query'

const useNohitsSearch = () => {
	const url = window.ReactUrls.noHits

	const {data, isRefetching, isLoading} = useQuery({
		queryKey: ['no-hits'],
		queryFn: async () => {
			const response = await fetch(url, {
				method: 'GET',
				headers: {'X-Requested-With': 'XMLHttpRequest'}
			})

			if (!response.ok) {
				return null
			}

			return response.text()
		}
	})

	return {isLoading, isRefetching, data}
}

export default useNohitsSearch
