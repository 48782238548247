import React, {useRef, useEffect, useState} from 'react'
import PropTypes from 'prop-types'

// Other
import useProductsAvailability from '../../hooks/use-products-availability'
import QuantityButtons from './partials/quantity-buttons'
import AddToCart from './partials/add-to-cart'
import WishlistBadge from './partials/wishlist-badge'
import Badge from './partials/badge'
import TileImage from './partials/tile-image'
import StarRating from '../star-rating'
import Price from '../price'
import {useIntl} from 'react-intl'

// Component Skeleton
export const Skeleton = () => {
	const intl = useIntl()

	return (
		<li className="product-grid__item">
			<div className="product-tile">
				<div className="product-tile-clickable">
					<div className="tile-image tile-image--mask">
						<div className="thumb-link">
							<div className="tile-image__stub"></div>
						</div>
					</div>

					<div className="product-tile__badges product-tile__badges--mask"></div>

					<div className="product-tile__content-wrapper">
						<div className="product-tile__name product-tile__name--mask"></div>
						<span className="product-tile__description product-tile__description--mask"></span>

						<div className="product-tile__reviews product-tile__reviews--mask"></div>

						{/* <div className="product-tile__variants product-tile__variants--mask">
							{intl.formatMessage({
								id: 'product.product.variant.text'
							})}
						</div> */}

						<div className="product-tile__pricing product-tile__pricing--mask">
							<div className="price-tile__wrapper">
								<div className="price-tile__sales price-tile__sales--mask">
									<span className="price-tile__amount price-tile__amount--mask">
										{intl.formatMessage({
											id: 'product.product.pricing.noprice'
										})}
									</span>
									<span className="price-tile__currency price-tile__currency--mask"></span>
								</div>
							</div>

							<div className="price-omnibus-text price-omnibus-text--tile price-omnibus-text--mask"></div>
						</div>
					</div>
				</div>
				<div className="product-tile__button">
					<div className="pdpForm">
						<button className="add-to-cart add-product-tile" disabled>
							<span className="add-product-tile__text">
								{intl.formatMessage({
									id: 'locale.global.addtocart.short'
								})}
							</span>
						</button>
					</div>
				</div>
			</div>
		</li>
	)
}

/**
 * The ProductTile is a simple visual representation of a
 * product object. It will show it's default image, name and price.
 * It also supports favourite products, controlled by a heart icon.
 */
const ProductTile = (props) => {
	const {product, productAvailability, isFavourite, productsSfccGeneral, index} = props
	const {attributes: productAttributes} = product

	const {
		image_link: image,
		image_link2: hoverImage,
		web_url: url,
		TrustmateReviewsCount: [trustmateReviewsCount] = [0],
		TrustmateAverageGrade: [trustmateAverageGrade] = [0],
		title,
		ShortDescription: description,
		ID: [id]
	} = productAttributes
	const [isDelayed, setIsDelayed] = useState(false)
	const addToCartForm = useRef(null)
	const [showHoverImage, setShowHoverImage] = useState(false)
	const [addedProduct, setAddedProduct] = useState([])
	const [cartInfo, setCartInfo] = useState(null)
	const [removedProductId, setRemovedProductId] = useState(null)
	const productAvailabilityAfterAdd = useProductsAvailability(addedProduct)
	const productAvailabilityAfterRemove = useProductsAvailability([removedProductId])
	const [lastAction, setLastAction] = useState(null)
	const [currentQty, setInputValue] = useState()
	const [maxQty, setMaxValue] = useState()
	const [minQty, setMinValue] = useState()

	const {badge, priceObj, gtmProductData} = productsSfccGeneral ?? {}

	useEffect(() => {
		let newCartInfo
		// Determine newCartInfo based on the last action
		if (lastAction === 'add') {
			newCartInfo = productAvailabilityAfterAdd?.[0]?.cart
		} else if (lastAction === 'remove') {
			newCartInfo = productAvailabilityAfterRemove?.[0]?.cart
		} else {
			// If no action has been performed yet or lastAction is null, fallback to productAvailability
			newCartInfo = productAvailability?.cart
		}

		setCartInfo(newCartInfo)
		setInputValue(newCartInfo?.quantity)
		setMinValue(newCartInfo?.quantity > 0 ? 1 : 0)
		setMaxValue(newCartInfo?.productMaxQuantity)
	}, [
		productAvailability,
		productAvailabilityAfterAdd,
		productAvailabilityAfterRemove,
		lastAction
	])

	useEffect(() => {
		const handleCustomEvent = () => {
			setIsDelayed(true)
			setAddedProduct([id])
			setLastAction('add')
			setTimeout(() => {
				setIsDelayed(false)
			}, 2000)
		}

		const handleRemovedEvent = (e) => {
			if (e.detail === id) {
				setRemovedProductId(id)
				setLastAction('remove')
			}
		}

		const myElement = addToCartForm.current

		myElement.addEventListener('addedtocart', handleCustomEvent)
		document.addEventListener('removedfromcart', handleRemovedEvent)

		return () => {
			myElement.removeEventListener('addedtocart', handleCustomEvent)
			document.removeEventListener('removedfromcart', handleRemovedEvent)
		}
	}, [id])

	const tileRedirect = (e) => {
		const eventEl = e.currentTarget
		const target = e.target

		if (!eventEl) {
			return
		}

		if (
			target &&
			target.classList.contains('tooltip-trigger') &&
			!target.classList.contains('js-tile-tooltip-trigger')
		) {
			return
		}

		const redirectURL = eventEl.getAttribute('data-href')

		if (!redirectURL) {
			return
		}

		setTimeout(() => {
			window.location.href = redirectURL
		}, 0)
	}

	return (
		<li className="product-grid__item" data-index={index + 1}>
			<div
				className={
					'product-tile js-gtm-product-tile ' +
					(isDelayed && 'product-tile--force-hover ') +
					(productAvailability?.nonAvailable && ' product-tile--non-available')
				}
				onMouseEnter={() => setShowHoverImage(true)}
				onMouseLeave={() => setShowHoverImage(false)}
			>
				<WishlistBadge isFavourite={isFavourite} id={id} />

				<div className="product-tile__clickable" data-href={url} onClick={tileRedirect}>
					<a href={url} className="product-tile__image">
						<TileImage
							image={image}
							hoverImage={hoverImage}
							alt={title}
							showHoverImage={showHoverImage}
						/>

						<div className="product-tile__badges">
							<Badge badge={badge} />
						</div>
					</a>

					<div className="product-tile__content-wrapper">
						<a href={url} className="product-tile__name">
							{title}
						</a>
						<span className="product-tile__description">{description}</span>

						<StarRating
							showCount={true}
							modifier={'tile'}
							trustmateAverageGrade={Number(trustmateAverageGrade)}
							trustmateReviewsCount={Number(trustmateReviewsCount)}
						/>

						<div className="product-tile__pricing">
							<Price price={priceObj} priceClass="tile" withLoyaltyPrice={true} />
						</div>
					</div>
				</div>

				<div className="product-tile__button">
					<form
						action=""
						method="post"
						className={'pdpForm js-gtm-tile-form-' + id}
						ref={addToCartForm}
						data-action-field="sr"
						data-product-gtm={JSON.stringify(gtmProductData)}
					>
						<fieldset>
							<input type="hidden" name="cartAction" value="add" />
							<input type="hidden" name="pid" value={id} />
							<input type="hidden" name="Quantity" value="1" />

							<QuantityButtons
								isDelayed={isDelayed}
								productAvailability={productAvailability}
								cartInfo={cartInfo}
								currentQty={currentQty}
								setInputValue={setInputValue}
								maxQty={maxQty}
								minQty={minQty}
							/>

							<AddToCart
								productAvailability={productAvailability}
								isDelayed={isDelayed}
							/>
						</fieldset>
					</form>
				</div>
			</div>
		</li>
	)
}

ProductTile.displayName = 'ProductTile'

ProductTile.propTypes = {
	/**
	 * The product search hit that will be represented in this
	 * component.
	 */
	product: PropTypes.shape({
		currency: PropTypes.string,
		image: PropTypes.shape({
			alt: PropTypes.string,
			disBaseLink: PropTypes.string,
			link: PropTypes.string
		}),
		price: PropTypes.number,
		// `name` is present and localized when `product` is provided by a RecommendedProducts component
		// (from Shopper Products `getProducts` endpoint), but is not present when `product` is
		// provided by a ProductList component.
		// See: https://developer.salesforce.com/docs/commerce/commerce-api/references/shopper-products?meta=getProducts
		name: PropTypes.string,
		// `productName` is localized when provided by a ProductList component (from Shopper Search
		// `productSearch` endpoint), but is NOT localized when provided by a RecommendedProducts
		// component (from Einstein Recommendations `getRecommendations` endpoint).
		// See: https://developer.salesforce.com/docs/commerce/commerce-api/references/shopper-search?meta=productSearch
		// See: https://developer.salesforce.com/docs/commerce/einstein-api/references/einstein-api-quick-start-guide?meta=getRecommendations
		// Note: useEinstein() transforms snake_case property names from the API response to camelCase
		productName: PropTypes.string,
		productId: PropTypes.string,
		hitType: PropTypes.string,
		attributes: PropTypes.object
	}),
	/**
	 * Enable adding/removing product as a favourite.
	 * Use case: wishlist.
	 */
	enableFavourite: PropTypes.bool,
	/**
	 * Display the product as a favourite.
	 */
	isFavourite: PropTypes.bool,
	/**
	 * Callback function to be invoked when the user
	 * interacts with favourite icon/button.
	 */
	onFavouriteToggle: PropTypes.func,
	productAvailability: PropTypes.object,
	productsSfccGeneral: PropTypes.object,
	index: PropTypes.number
}

export default ProductTile
