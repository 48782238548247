import {useQuery} from '@tanstack/react-query'

const useContentAsset = (cid) => {
	const url = window.ReactUrls.pageShowAsset

	const {data, isRefetching, isLoading} = useQuery({
		queryKey: ['content-asset', cid],
		queryFn: async () => {
			const response = await fetch(url + '?cid=' + cid, {
				method: 'GET'
			})

			if (!response.ok) {
				return null
			}

			return response.text()
		}
	})

	return {isLoading, isRefetching, data}
}

export default useContentAsset
