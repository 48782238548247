export default {
	elements_selector: '.lazy',
	class_applied: 'lz-applied',
	class_loading: 'lazy--loading',
	class_loaded: 'lazy--loaded',
	class_error: 'lazy--error',
	unobserve_entered: true,
	callback_loaded: function (element) {
		element.removeAttribute('data-src')
		const pictureContainer = element.parentElement

		if (!pictureContainer) {
			return
		}

		const sourceElements = pictureContainer.querySelectorAll('source')
		sourceElements.forEach((source) => {
			source.removeAttribute('data-srcset')
		})
	}
}
