import {useEffect, useState, useRef} from 'react'

const useProductsAvailability = (ids) => {
	const url = window.ReactUrls.productsAvailability

	const [productsAvailability, setProductsAvailability] = useState(null)
	const prevIdsRef = useRef([])

	useEffect(() => {
		if (
			!ids ||
			ids.length == 0 ||
			!ids[0] ||
			JSON.stringify(ids) === JSON.stringify(prevIdsRef.current)
		) {
			return
		}

		fetch(url + '?products=' + ids.join(','), {
			method: 'POST',
			headers: {'X-Requested-With': 'XMLHttpRequest'}
		})
			.then((response) => {
				if (!response.ok) {
					return
				}

				return response.json()
			})
			.then((data) => setProductsAvailability(data))

		prevIdsRef.current = ids
	}, [ids])

	return productsAvailability
}

export default useProductsAvailability
