import React from 'react'
import PropTypes from 'prop-types'
import {useIntl} from 'react-intl'
import {useLocation} from 'react-router-dom'
import {useSearchParams} from '../../../hooks/use-search-params'

const PageHeader = ({searchQuery, productSearchResult}) => {
	const intl = useIntl()
	const location = useLocation()
	const urlParams = new URLSearchParams(location.search)
	const offsetParam = urlParams.get('offset')
	const [searchParams] = useSearchParams()
	const limitParam = searchParams.limit
	const pageParam = Math.round(offsetParam / limitParam + 1)

	return (
		<div className="category-header">
			<div className="category-header__search-text">
				{intl.formatMessage({id: 'search.search.phrase'})}
			</div>

			<span className="category-header__title-wrapper">
				{productSearchResult?.results?.corrected_query ? (
					<span
						className="category-header__title"
						dangerouslySetInnerHTML={{
							__html: productSearchResult?.results?.corrected_query
						}}
					></span>
				) : (
					<span className="category-header__title">{searchQuery}</span>
				)}

				{pageParam > 1 && (
					<span className="category-header__pagination js-category-page-number">
						{' '}
						({intl.formatMessage({id: 'search.search.page'})} {pageParam})
					</span>
				)}
			</span>
		</div>
	)
}

PageHeader.propTypes = {
	category: PropTypes.object,
	productSearchResult: PropTypes.object,
	searchQuery: PropTypes.string
}

export default PageHeader
