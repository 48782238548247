import React, {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import EmptySearchResults from './partials/empty-results'
import SelectedRefinements from './partials/selected-refinements'
import PageHeader from './partials/page-header'
import {useSearchParams} from '../../hooks/use-search-params'
import useNavigation from '../../hooks/use-navigation'
import useProductSearch from '../../hooks/use-product-search'
import useContentAsset from '../../hooks/use-content-asset'
import {useWishList} from '../../hooks/use-wish-list'
import useProductsAvailability from '../../hooks/use-products-availability'
import useProductsSfccGeneral from '../../hooks/use-products-sfcc-general'
import usePageUrls from '../../hooks/use-page-urls'
import Refinements from './partials/refinements'
import Sort from './partials/sort'
import ProductTile, {Skeleton as ProductTileSkeleton} from '../../components/product-tile'
import Pagination from '../../components/pagination'
import {
	excludeCategoryRefinements,
	filterOutPartialCategoryRefinements
} from '../../utils/refinement-utils'
import {scrollToElement} from '../../utils/utils'
import {useLocation, useParams} from 'react-router-dom'

const ProductList = () => {
	const intl = useIntl()
	const location = useLocation()
	const navigate = useNavigation()
	const urlParams = new URLSearchParams(location.search)
	const searchQuery = urlParams.get('q')
	const params = useParams()
	const [searchParams, {stringify: stringifySearchParams}] = useSearchParams()
	const wishlist = useWishList()

	if (params.categoryId) {
		searchParams._refine.push(`cgid=${params.categoryId}`)
	}

	const {
		isLoading,
		isRefetching,
		data: productSearchResult
	} = useProductSearch(
		{
			parameters: {
				...searchParams,
				refine: searchParams._refine
			}
		},
		{
			keepPreviousData: true
		}
	)

	const basePath = `${location.pathname}${location.search}`
	const showNoResults =
		!isLoading && productSearchResult && !productSearchResult?.results?.hits.length
	const total = productSearchResult?.results?.total_hits

	const productIDs = productSearchResult?.results?.hits.map((el) => {
		return el.attributes.ID[0]
	})
	const productsAvailability = useProductsAvailability(productIDs)
	const {data: productsSfccGeneral} = useProductsSfccGeneral(productIDs)

	const pageUrls = usePageUrls({total})

	const {data: contentAsset} = useContentAsset('order-of-products-information')

	// Toggles filter on and off
	const toggleFilter = (value, attributeId, selected) => {
		const searchParamsCopy = {...searchParams}

		// Remove the `offset` search param if present.
		delete searchParamsCopy.offset

		// Get the attibute value as an array.
		let attributeValue = searchParamsCopy.refine[attributeId] || []

		if (attributeValue === true) {
			attributeValue = attributeValue.toString()
		}

		// Ensure that the value is still converted into an array if it's a `string` or `number`.
		if (typeof attributeValue === 'string') {
			attributeValue = attributeValue.split('|')
		} else if (typeof attributeValue === 'number') {
			attributeValue = [attributeValue]
		}

		if (attributeId == 'price') {
			attributeValue = []
		}

		if (attributeId === 'category') {
			if (selected) {
				attributeValue = excludeCategoryRefinements(attributeValue, value.value)
			} else {
				attributeValue = filterOutPartialCategoryRefinements(attributeValue, value.value)
			}
		}

		// Either set the value, or filter the value out.
		if (!selected) {
			attributeValue.push(value.value)
		} else {
			// Note the loose comparison, for "string != number" checks.
			attributeValue = attributeValue?.filter((v) => v != value.value)
		}

		// Update the attribute value in the new search params.
		searchParamsCopy.refine[attributeId] = attributeValue

		// If the update value is an empty array, remove the current attribute key.
		if (searchParamsCopy.refine[attributeId].length === 0) {
			delete searchParamsCopy.refine[attributeId]
		}

		navigate(`/search?${stringifySearchParams(searchParamsCopy)}`)
		scrollToElement('.search-results')
	}

	// Clears all filters
	const resetFilters = () => {
		const newSearchParams = {
			...searchParams,
			refine: []
		}
		const newPath = `/search?${stringifySearchParams(newSearchParams)}`

		navigate(newPath)
	}

	const [openRefinementPopUp, setOpenRefinementPopUp] = useState(false)

	const [eventTriggered, setEventTriggered] = useState(false)
	useEffect(() => {
		if (!eventTriggered && !isLoading && productSearchResult && productsSfccGeneral) {
			const event = new Event('searchResultProductPriningCompleted')
			document.dispatchEvent(event)
			setEventTriggered(true)
		}
	}, [isLoading, productSearchResult, productsSfccGeneral, eventTriggered])

	return (
		searchQuery && (
			<>
				{showNoResults ? (
					<EmptySearchResults searchQuery={searchQuery} />
				) : (
					<div className="section-row">
						<PageHeader
							searchQuery={searchQuery}
							productSearchResult={productSearchResult}
							isLoading={isLoading}
						/>

						<div className="search-results">
							<div className="search-results__sidebar">
								<Refinements
									toggleFilter={toggleFilter}
									filters={productSearchResult?.results?.facets}
									selectedFilters={searchParams.refine}
									openRefinementPopUpState={openRefinementPopUp}
									setOpenRefinementPopUp={setOpenRefinementPopUp}
									productSearchResult={productSearchResult}
									basePath={basePath}
									handleReset={resetFilters}
								/>
							</div>
							<div className="search-results__products">
								<div className="category-controls">
									<div className="category-controls__row">
										<div
											className="category-controls__text"
											dangerouslySetInnerHTML={{__html: contentAsset}}
										></div>
										<div className="category-controls__sort">
											<Sort basePath={basePath} />
										</div>
									</div>
									<div className="category-controls__items-refinement">
										<div className="category-controls__total-items">
											{intl.formatMessage(
												{id: 'product.product.count.text'},
												{param0: productSearchResult?.results?.total_hits}
											)}
										</div>
										<div
											className="category-controls__refinement"
											onClick={() => setOpenRefinementPopUp(true)}
										>
											{intl.formatMessage({
												id: 'search.searchrefinebar.title'
											})}
										</div>
									</div>

									<SelectedRefinements
										filters={productSearchResult?.results?.facets}
										toggleFilter={toggleFilter}
										handleReset={resetFilters}
										selectedFilterValues={productSearchResult?.results?.filters}
									/>
								</div>

								<div className="search-result-content">
									<ul className="product-grid tiles-container">
										{isRefetching ||
										!productSearchResult ||
										!productsSfccGeneral
											? new Array(searchParams.limit)
													.fill(0)
													.map((value, index) => (
														<ProductTileSkeleton key={index} />
													))
											: productSearchResult?.results?.hits?.map(
													(productSearchItem, index) => {
														const productId =
															productSearchItem.attributes.ID[0]

														return (
															<ProductTile
																key={productId}
																product={productSearchItem}
																index={index}
																productAvailability={
																	productsAvailability?.[index]
																}
																productsSfccGeneral={
																	productsSfccGeneral?.[index]
																}
																isFavourite={
																	wishlist.indexOf(productId) > -1
																}
															/>
														)
													}
												)}
									</ul>
									<Pagination currentURL={basePath} urls={pageUrls} />
								</div>
							</div>
						</div>
					</div>
				)}
			</>
		)
	)
}

export default ProductList
