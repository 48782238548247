import React, {useState} from 'react'
import PropTypes from 'prop-types'
import {useIntl} from 'react-intl'
import Icon from '../../../components/icon'

const BrandRefinements = ({filter, toggleFilter, selectedFilters}) => {
	const intl = useIntl()
	const [searchText, setSearchText] = useState('')
	const showCloseIcon = !!searchText

	const getFilteredArr = () => {
		const map = new Map()
		var arr = filter?.values.filter((item) => {
			return item.value.toLowerCase().indexOf(searchText) !== -1
		})
		arr.sort((a, b) => a.value.localeCompare(b.value)).forEach((value) => {
			!map.has(value.value[0])
				? map.set(value.value[0], [value])
				: map.get(value.value[0]).push(value)
		})

		return [...map]
	}

	const filteredBrands = getFilteredArr()

	return (
		<div>
			<div id="refinement-by-brand" className="refinement-brand__search js-refinement-brand">
				<input
					type="text"
					className="refinement-brand__input js-refinement-brand-input"
					id="brandSearch"
					name="brandSearch"
					value={searchText}
					onChange={({target}) => setSearchText(target.value)}
					placeholder={intl.formatMessage({
						id: 'search.global.refinements.refinementbybrand'
					})}
					autoComplete="off"
				/>

				<Icon
					id={'icon-search'}
					className={'refinement-brand__icon refinement-brand__icon--visible'}
				/>
				<Icon
					id={'icon-closethin'}
					className={
						'refinement-brand__icon ' +
						(showCloseIcon && 'refinement-brand__icon--visible')
					}
					onClick={() => setSearchText('')}
				/>
			</div>

			<ul className="refinement-brand__container">
				{filteredBrands.map(([key, values]) => {
					return (
						<React.Fragment key={key}>
							<li className="refinement-brand__alphabet">
								<strong>{key}</strong>
							</li>
							{values.map((value) => {
								const isChecked = selectedFilters.includes(value.value)
								return (
									<li
										className="refinement__list refinement-brand__list"
										data-gtm-value={value.value}
										key={value.value}
									>
										<a
											title={
												intl.formatMessage({
													id: 'search.search.productsearchrefinebar.clickrefine'
												}) + value.value
											}
										>
											<input
												className="input-checkbox"
												id={value.value + '-brand'}
												type="checkbox"
												checked={isChecked}
												onChange={() =>
													toggleFilter(value, filter.name, isChecked)
												}
											/>

											<label
												className="checkbox-label"
												htmlFor={value.value + '-brand'}
											>
												{value.value}
												<span>({value.hits_count})</span>
											</label>
										</a>
									</li>
								)
							})}
						</React.Fragment>
					)
				})}
			</ul>
		</div>
	)
}

BrandRefinements.propTypes = {
	filter: PropTypes.object,
	toggleFilter: PropTypes.func,
	selectedFilters: PropTypes.array
}

export default BrandRefinements
