import React, {useEffect} from 'react'
import PropTypes from 'prop-types'
import {useIntl} from 'react-intl'
import useNohitsSearch from '../../../hooks/use-nohits-search'

const EmptySearchResults = ({searchQuery}) => {
	const intl = useIntl()
	const {data, isLoading} = useNohitsSearch()

	useEffect(() => {
		if (!isLoading) {
			const event = new Event('noHitsLoaded')
			document.dispatchEvent(event)
		}
	}, [isLoading])

	return (
		<React.Fragment>
			<div className="section-header">
				<h3 className="section-header__title">
					<span className="section-header__title-message">
						{intl.formatMessage({
							id: 'search.search.nohits.message'
						})}
					</span>{' '}
					&quot; {searchQuery} &quot;
				</h3>
			</div>

			{isLoading ? (
				<div className="react-loader">
					<div className="loader loader--absolute">
						<div className="loader__ellipsis">
							<div></div>
							<div></div>
							<div></div>
							<div></div>
						</div>
					</div>
				</div>
			) : (
				<div dangerouslySetInnerHTML={{__html: data}}></div>
			)}
		</React.Fragment>
	)
}

EmptySearchResults.propTypes = {
	searchQuery: PropTypes.string,
	category: PropTypes.object
}

export default EmptySearchResults
