import * as requester from '../api/lbx-requester'
import {selectedCategoryRefinementsList} from '../utils/refinement-utils'
import {getFacetDisplayName, buildFacetsQuery} from '../utils/refinement-utils'

const BASE_URL = 'https://live.luigisbox.com'
const LBX_TRACKER_ID = window.ReactPreferences.LBX_TRACKER_ID

export const getProductSearch = async (parameters) => {
	const query = parameters.q
	const sort = parameters.sort
	const refine = parameters.refine
	const limit = parameters.limit
	const offset = parameters.offset
	const facetsQuery = buildFacetsQuery()

	const url = new URL(BASE_URL + '/search')
	const params = new URLSearchParams()

	params.append('q', query)
	params.append('tracker_id', LBX_TRACKER_ID)
	params.append('f[]', 'type:item')
	params.append('facets', facetsQuery)
	params.append('page', Math.round(offset / limit + 1))
	params.append('size', limit)

	if (sort !== 'best-matches') {
		params.append('sort', sort)
	}

	url.search = params

	for (var i = 0; i < refine.length; i++) {
		var item = refine[i]
		var itemArr = item.split('=')
		var refID = itemArr[0]
		var values = itemArr[1]

		var valuesArr = values.split('|')
		let value = ''

		var refDisplayName = getFacetDisplayName(refID)

		if (refID === 'category') {
			var categoryRefinementsList = selectedCategoryRefinementsList(valuesArr)
			for (let j = 0; j < categoryRefinementsList.length; j++) {
				params.append('f[]', refDisplayName + ':' + categoryRefinementsList[j])
			}
		} else if (refID !== 'price') {
			for (let j = 0; j < valuesArr.length; j++) {
				value = valuesArr[j]
				params.append('f[]', refDisplayName + ':' + value)
			}
		} else {
			for (let j = 0; j < valuesArr.length; j += 2) {
				value = valuesArr[j] + '|' + valuesArr[j + 1]
				params.append('f[]', refDisplayName + ':' + value)
			}
		}
	}

	url.search = params

	return await requester.get(url)
}

export const getSuggestions = async (query) => {
	return await requester.get(
		`${BASE_URL}/autocomplete/v2?q=${query}&tracker_id=${LBX_TRACKER_ID}&type=query:5,category:5,item:6`
	)
}

export const getPopularSearch = async () => {
	return await requester.get(`${BASE_URL}/v2/trending_queries?tracker_id=${LBX_TRACKER_ID}`)
}
