import React from 'react'
import PropTypes from 'prop-types'

const CustomRefinements = ({filter, toggleFilter, selectedFilters}) => {
	return (
		<div>
			<ul>
				{filter.values.map((item) => {
					let subFilter
					for (let i = 0; i < item.values.length; i++) {
						if (item.values[i].value !== 'true') {
							continue
						}

						subFilter = item.values[i]
					}

					if (!subFilter) {
						return
					}

					const isSelected = selectedFilters.indexOf(item.name) > -1

					return (
						<li className="refinement__list" key={item.name} data-gtm-value={item.name}>
							<a className="refinement-category__link">
								<input
									className="input-checkbox"
									id={item.name + '-custom'}
									type="checkbox"
									checked={isSelected}
									onChange={() => toggleFilter(subFilter, item.name, isSelected)}
								/>

								<label className="checkbox-label" htmlFor={item.name + '-custom'}>
									{item.name}
									<span>({subFilter.hits_count})</span>
								</label>
							</a>
						</li>
					)
				})}
			</ul>
		</div>
	)
}

CustomRefinements.propTypes = {
	filter: PropTypes.object,
	toggleFilter: PropTypes.func,
	selectedFilters: PropTypes.array
}

export default CustomRefinements
