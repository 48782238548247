import {useEffect, useState} from 'react'
import useLocalStorage from '../hooks/use-local-storage'

export const useUserState = () => {
	const [isAuthenticated, setAuthenticatedState] = useState([])

	const userLocal = useLocalStorage('userLocal')

	useEffect(() => {
		if (!userLocal) {
			return
		}

		const {isAuthenticated} = JSON.parse(userLocal)
		setAuthenticatedState(isAuthenticated)
	}, [userLocal])

	return isAuthenticated
}
