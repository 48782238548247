import React from 'react'
import PropTypes from 'prop-types'
import Icon from '../../../components/icon'
import {useIntl} from 'react-intl'

const AddToCart = ({productAvailability, isDelayed}) => {
	const intl = useIntl()
	const isNotifyMeEnabled = window.ReactPreferences.NOTIFY_ME_ENABLED

	const triggerSubscribeEvent = (e) => {
		e.preventDefault()
		const customEvent = new CustomEvent('subscribeEvent', {
			detail: e.target
		})
		document.dispatchEvent(customEvent)
	}

	const triggerAddToCartEvent = (e) => {
		e.preventDefault()
		const customEvent = new CustomEvent('addToCartEvent', {
			detail: e.target
		})
		document.dispatchEvent(customEvent)
	}

	return (
		<React.Fragment>
			{productAvailability?.nonAvailable ? (
				isNotifyMeEnabled ? (
					<button
						type="button"
						title={intl.formatMessage({id: 'locale.global.product.subscribe'})}
						onClick={triggerSubscribeEvent}
						value={intl.formatMessage({id: 'locale.global.product.subscribe'})}
						className="add-to-cart add-product-tile add-product-tile--outline'}"
					>
						{intl.formatMessage({id: 'locale.global.product.subscribe'})}
					</button>
				) : (
					<button
						type="button"
						title={intl.formatMessage({id: 'locale.global.productnotavailable'})}
						value={intl.formatMessage({id: 'locale.global.productnotavailable'})}
						className="add-product-tile add-to-cart"
						disabled="disabled"
					>
						{intl.formatMessage({id: 'locale.global.product.unavailable'})}
					</button>
				)
			) : (
				!isDelayed && (
					<button
						className="add-to-cart add-product-tile"
						type="submit"
						onClick={triggerAddToCartEvent}
					>
						<span className="add-product-tile__text">
							{intl.formatMessage({id: 'locale.global.addtocart'})}
						</span>
					</button>
				)
			)}

			{isDelayed && (
				<div className="add-product-tile add-product-tile--green">
					<Icon className={'add-product-tile__icon'} id={'icon-check'} />
					<span className="add-product-tile__text">
						{intl.formatMessage({id: 'locale.global.justadded'})}
					</span>
				</div>
			)}
		</React.Fragment>
	)
}

AddToCart.propTypes = {
	productAvailability: PropTypes.object,
	isDelayed: PropTypes.bool
}

export default AddToCart
