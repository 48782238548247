import React from 'react'
import PropTypes from 'prop-types'
import {useUserState} from '../../hooks/use-user-state'
import PriceSales from './pricesales'
import OmnibusPricing from './omnibuspricing'
import LoyaltyPrice from './loyaltyprice'

const Price = (props) => {
	const {price, priceClass, withLoyaltyPrice} = props
	const isAuthenticated = useUserState()

	if (!price) {
		return
	}

	const {OmnibusPrice, PriceLoyalty, SalesPrice, isPromoPrice, assets} = price
	const displayFormattedPrice =
		priceClass === 'tile' || priceClass === 'tile-slider' || priceClass === 'product'
	const hasLoyaltyPrice = withLoyaltyPrice !== false && PriceLoyalty > 0

	//Display loyalty separately for not logged in customers as additional information underneath
	const isAdditionalLoyaltyInfoDisplayed = hasLoyaltyPrice && !isAuthenticated
	const hideAdditionalPriceInfo = priceClass === 'package'
	const showOmnibus = isPromoPrice || isAdditionalLoyaltyInfoDisplayed

	return (
		<React.Fragment>
			<div className={'price-' + priceClass + '__wrapper'}>
				<PriceSales
					priceClass={priceClass}
					isPromoPrice={isPromoPrice}
					SalesPrice={SalesPrice}
					displayFormattedPrice={displayFormattedPrice}
				/>
			</div>

			{!hideAdditionalPriceInfo && (
				<div className={'price-' + priceClass + '__footer'}>
					{isAdditionalLoyaltyInfoDisplayed && (
						<LoyaltyPrice
							priceClass={priceClass}
							priceLoyalty={PriceLoyalty}
							assets={assets}
						/>
					)}

					{showOmnibus && (
						<OmnibusPricing
							priceClass={priceClass}
							omnibusPrice={OmnibusPrice}
							isAdditionalLoyaltyInfoDisplayed={isAdditionalLoyaltyInfoDisplayed}
							assets={assets}
						/>
					)}
				</div>
			)}
		</React.Fragment>
	)
}

Price.propTypes = {
	price: PropTypes.object,
	priceClass: PropTypes.string,
	withLoyaltyPrice: PropTypes.bool
}

export default Price
